<template>
  <div class="swiper-slide">
    <a
      href="#"
      class="card"
      :class="{
        'out-of-stock': !inStock,
        passed: productState === 'passed',
        highlighted: productState === 'highlighted',
      }"
      v-on:click.prevent="onClick"
    >
      <div :class="['card-img', { 'fallback': !hasThumb }]">
        <img :src="thumb" :alt="title" :title="title" />
      </div>
      <div class="card-body">
        <div
          class="inner pe-md-3 pe-xl-4 d-flex align-items-center w-100 flex-wrap flex-md-nowrap"
        >
          <div class="flex-grow-1">
            <div class="card-title"><p><strong>{{ title }}</strong></p></div>
            <div v-if="hasPromo" class="card-price d-flex">
              <div class="c-original me-2">€ {{ price }}</div>
              <div class="c-discount">€ {{ promoPrice }}</div>
            </div>
            <div v-else class="card-price d-flex">
              <div class="c-unit">€ {{ price }}</div>
            </div>
            <div v-if="!inStock" class="card-stock mt-2">
              <small>{{ $t("productDetail.outOfStock") }}</small>
            </div>
          </div>

          <div class="card-wishlist-container">
            <span
              @click.stop="onAddToWishlist"
              class="btn-wishlist"
              :class="{ active: inWishlist }"
              href="#"
            >
              <svg class="icon-inactive" width="20" height="18">
                <use href="#icon-wishlist-inactive"></use>
              </svg>

              <svg class="icon-active" width="20" height="18">
                <use href="#icon-wishlist-active"></use>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import { ref, toRef, computed } from "vue";
export default {
  setup(props) {
    const id = ref(props.productInfo.id);
    const title = ref(props.variantInfo.title);
    const descr = ref(props.variantInfo.description);
    const hasPromo = ref(
      props.variantInfo.promotionPrice !== null &&
        props.variantInfo.promotionPrice > 0
    );
    const price = ref(parseFloat(props.variantInfo.price).toFixed(2));
    const promoPrice = ref(
      parseFloat(props.variantInfo.promotionPrice).toFixed(2)
    );
    const variantCode = ref(props.variantInfo.variantCode);
    const productState = ref("default");
    const inWishlist = toRef(props, "inWishlist");

    const inStock = computed(() => {
      let returnValue = false;

      props.variantInfo.sizes.forEach((size) => {
        if (size.inStock) {
          returnValue = true;
        }
      });

      return returnValue;
    });

    const thumb = ref(props.variantInfo.thumb);
    const hasThumb = checkImageUrl(props.variantInfo.thumb);
    function checkImageUrl(url) {
      const pattern = /\.(jpg|jpeg|png)$/i;
      return pattern.test(url);
    }

    return {
      id,
      title,
      descr,
      hasPromo,
      price,
      promoPrice,
      thumb,
      hasThumb,
      inStock,
      productState,
      inWishlist,
      baseUrl,
      variantCode,
    };
  },
  methods: {
    onClick(e) {
      this.$parent.$parent.$emit("productSelected", {
        id: this.id,
        variantCode: this.variantCode,
      });
    },
    onAddToWishlist(e) {
      this.$parent.$parent.$emit("onToggleWishlist", this.variantCode);
    },
  },
  props: ["productInfo", "variantInfo", "inWishlist"],
};
</script>
