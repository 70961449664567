<template>
  <div
    ref="chatMessagesContainer"
    class="c-chat-body flex-fill pe-4 px-lg-4 px-xxl-5 py-4"
    :class="{ unregistered: !user.id }"
  >
    <div
      v-for="(message, index) in messages"
      :key="message.messageId"
      class="c-chat-item"
      :class="{ 'c-chat-item--removed': message.status === 'removed' }"
    >
      <div v-if="message.user.id !== user.id" class="row gx-3">
        <div class="col-auto">
          <div v-if="message.user.id === 1">
            <div class="c-chat-person rounded text-white bg-primary">
              <svg width="20" height="20">
                <use href="#icon-mayerline"></use>
              </svg>
            </div>
          </div>
          <div v-else>
            <div
                class="c-chat-person rounded"
                :class="'bg-' + message.user.color"
            >
              {{ message.user.userName }}
            </div>
          </div>
        </div>
        <div class="col">
          <div class="c-balloon left">
            <p>
              {{ message.message }}
            </p>
          </div>
        </div>
      </div>
      <div v-else class="row gx-3 justify-content-end">
        <div class="col-auto">
          <div class="c-balloon bg-light">
            <p>
              {{ message.message }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="c-chat-bottom px-3 px-md-4 px-xxl-5"
    :class="{ unregistered: !user.id, disabled: chatClosed }"
  >
    <form
      class="row gx-0 align-items-center py-3 py-xxl-4"
      @submit.prevent="onSendMessage"
    >
      <div class="col">
        <textarea
          id="message"
          v-model.trim="userMessage"
          class="form-input w-100"
          type="text"
          placeholder="Typ je boodschap hier"
          ref="messageInput"
          @input="onChangeInput"
        />
      </div>
      <div class="ms-2 col-auto d-flex">
        <div
          class="emoji-picker-wrapper"
          dataSource="https://live.jbc.hosted-temp.com/emoji.json"
          v-show="emojiPanelShown"
          v-click-outside="onClickOutside"
        >
          <emoji-picker v-pre ref="emojiPicker" class="light"></emoji-picker>
        </div>

        <button
          @click="toggleEmojiPanel"
          type="button"
          class="btn btn--round btn-smiley btn-gray me-2"
        >
          <svg width="18" height="18">
            <use href="#icon-smiley"></use>
          </svg>
        </button>
        <button
          type="submit"
          class="btn btn--round btn-send btn-primary"
          :disabled="userMessage.length <= 0 || !user.id"
        >
          <svg width="19" height="16">
            <use href="#icon-send"></use>
          </svg>
        </button>
      </div>
    </form>
  </div>

  <div v-if="!chatClosed" v-show="!user.id" class="c-chat-register">
    <form @submit.prevent="onUsernameSubmitted">
      <label for="username">{{ $t("chat.labelUserName") }}</label>
      <div>
        <input
          type="text"
          v-model="userName"
          id="username"
          class="form-input w-100"
          :placeholder="$t('chat.placeholder')"
          @input="onChangeUsername"
        />
      </div>
      <div>
        <button
          type="submit"
          class="btn btn-cart-add rounded-1 d-flex align-items-center justify-content-center text-uppercase mt-3 w-100"
          :class="{
            'btn-danger': userStatus === 'banned',
            'btn-primary': userStatus !== 'banned',
          }"
          :disabled="userName.length <= 0 && userStatus !== 'banned'"
        >
          <span class="d-flex flex-row align-items-center justify-content-center" v-if="userStatus !== 'banned'">
            <svg width="19" height="16">
              <use href="#icon-user"></use>
            </svg>
            {{ $t("chat.register") }}
          </span>
          <span v-else>
            <svg width="19" height="16" style="fill: white !important;">
              <use href="#icon-banned-user"></use>
            </svg>
            {{ $t("chat.banned") }}
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { ref, toRef, watch, watchEffect } from "vue";
import "emoji-picker-element";

export default {
  setup(props) {
    const userName = ref("");
    const userMessage = ref("");
    const userStatus = ref("");
    const messages = ref([]);
    const emojiPanelShown = ref(false);
    const userLoadedFromSessionData = ref(false);
    const chatClosed = props.playerState !== "ended" ? ref(false) : ref(true);

    //Chat stuff
    let defaultUserObject = {
      id: false,
      username: "",
      role: "user",
      color: "",
      fingerPrint: "",
    };

    const userFromSessionStorage = sessionStorage.user
      ? JSON.parse(sessionStorage.user)
      : false;
    if (userFromSessionStorage) {
      if (userFromSessionStorage.expires > Date.now()) {
        defaultUserObject = userFromSessionStorage;
        userLoadedFromSessionData.value = true;
      }
    }
    const user = ref(defaultUserObject);

    return {
      messages,
      user,
      userMessage,
      userName,
      userStatus,
      emojiPanelShown,
      userLoadedFromSessionData,
      chatClosed,
    };
  },
  components: {},
  props: ["socket", "playerState"],
  emits: ["sendChatMessage", "startChatting"],
  directives: {
    "click-outside": {
      beforeMount: function (el, binding) {
        // Define ourClickEventHandler
        const ourClickEventHandler = (event) => {
          if (!el.contains(event.target) && el !== event.target) {
            // as we are attaching an click event listern to the document (below)
            // ensure the events target is outside the element or a child of it
            binding.value(event); // before binding it
          }
        };

        // attached the handler to the element so we can remove it later easily
        el.__vueClickEventHandler__ = ourClickEventHandler;

        // attaching ourClickEventHandler to a listener on the document here
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function (el) {
        // Remove Event Listener
        document.removeEventListener("click", el.__vueClickEventHandler__);
      },
    },
  },
  watch: {
    playerState: function (newValue, oldValue) {
      if (newValue === "scheduled") {
        if (oldValue === "ended") {
          //Clear chat messages
          this.messages = [];

          //Reset user
          this.userFromSessionStorage = false;
          sessionStorage.removeItem("user");
          this.user = {
            id: false,
            username: "",
            role: "user",
            color: "",
            fingerPrint: "",
          };
        }
        this.chatClosed = false;
      }
    },
  },
  methods: {
    getTextareaRef() {
      return this.$refs.messageInput;
    },
    onChangeInput() {
      this.resizeTextarea();
    },
    resizeTextarea() {
      const el = this.getTextareaRef();

      if (!el) return;

      const padding = window
        .getComputedStyle(el, null)
        .getPropertyValue("padding-top")
        .replace("px", "");

      el.style.height = 0;
      el.style.height = el.scrollHeight + "px";
    },
    scrollToBottom() {
      const element = this.$refs.chatMessagesContainer;
      element.classList.add("c-chat-body--scroll-smooth");
      element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
      setTimeout(() =>
        element.classList.remove("c-chat-body--vac-scroll-smooth")
      );
    },
    onUsernameSubmitted() {
      let acronym = this.userName
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");

      acronym = acronym.substring(0, 2);

      let _self = this;
      this.user.username = acronym;
      this.socket.emit(
        "registerChatUser",
        {
          acronym: acronym,
          fingerPrint: this.user.fingerPrint,
        },
        function (data) {
          if (data.userStatus === "banned") {
            console.error("You were banned!");
            _self.user.id = "";
            _self.userStatus = "banned";
            sessionStorage.removeItem("user");
            return;
          }

          _self.user.id = data.userId;
          _self.user.color = data.userColor;

          let copy = { ..._self.user };
          delete copy.fingerPrint;
          copy.expires = Date.now() + 6 * 60 * 60 * 1000; //Users can only last 6h!

          sessionStorage.setItem("user", JSON.stringify(copy));

          setTimeout(function () {
            _self.scrollToBottom();
          }, 50);
        }
      );
    },
    onSendMessage() {
      if (this.chatClosed) return;

      let msg = {
        user: this.user,
        message: this.userMessage,
        timestamp: Date.now(),
      };

      let _self = this;
      this.socket.emit("sendChatMessage", msg, function (id) {
        msg.messageId = id;
        _self.messages.push(msg);

        //Reset input
        _self.userMessage = "";

        setTimeout(function () {
          _self.resizeTextarea();
          _self.scrollToBottom();
        }, 50);
      });
    },
    toggleEmojiPanel() {
      this.emojiPanelShown = !this.emojiPanelShown;
    },
    onClickOutside(e) {
      if (!e.target.closest(".btn-smiley")) {
        this.emojiPanelShown = false;
      }
    },
  },
  mounted() {
    let _self = this;
    let lastMessage = false;

    // this.socket.on("userRegistered", );
    this.socket.on("chatOpen", function (data) {
      _self.chatClosed = false;
    });

    this.socket.on("chatClose", function (data) {
      _self.chatClosed = true;

      //Clear the old chat messages / users
    });

    this.socket.on("chatHistory", function (data) {
      for (let index in data) {
        _self.messages.push(data[index]);
      }

      setTimeout(function () {
        _self.scrollToBottom();
      }, 50);
    });

    this.socket.on("newChatMessage", function (data) {
      //Add the latest message to the bunch
      _self.messages.push(data);

      setTimeout(function () {
        _self.scrollToBottom();
      }, 50);
    });

    this.socket.on("youveBeenBanned", function (data) {
      _self.user.id = "";
      _self.userStatus = "banned";
      sessionStorage.removeItem("user");
    });

    this.socket.on("chatMsgWasRemoved", function (data) {
      if ("messageId" in data) {
        _self.messages = _self.messages.filter(
          (obj) => obj.messageId !== data.messageId
        );
      } else if ("userId" in data) {
        _self.messages = _self.messages.filter(
          (obj) => obj.user.id !== data.userId
        );
      }
    });

    this.socket.emit("getChatHistory", {}, function (data) {
      _self.messages = data;
    });

    this.getTextareaRef().addEventListener("keydown", (e) => {
      if (e.key === "Enter" && e.shiftKey) {
        e.preventDefault();
        _self.userMessage = _self.userMessage + "\n";
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (_self.userMessage.length > 0 && _self.user.id) {
          _self.onSendMessage();
        }
      }
    });

    this.$refs.emojiPicker.shadowRoot.addEventListener(
      "emoji-click",
      ({ detail }) => {
        _self.userMessage = _self.userMessage + detail.unicode;
      }
    );

    this.$refs.emojiPicker.shadowRoot.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        //Close emoji's
        this.toggleEmojiPanel();

        //Focuse chat text area
        this.getTextareaRef().focus();
      }
    });

    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load();
    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        this.user.fingerPrint = result.visitorId;

        //Check if user was loaded form session data
        //Allow server to update socket id
        if (this.userLoadedFromSessionData) {
          let _self = this;
          this.socket.emit(
            "reconnectChatUser",
            { userId: this.user.id, userFingerPrint: this.user.fingerPrint },
            function (data) {
              if (data.status === "banned") {
                //Ban user!
                _self.user.id = false;
                _self.userStatus = "banned";
                sessionStorage.removeItem("user");
              } else if (data.status === "reconnected") {
                console.log("Chat user was reconnected!");
              } else if (data.status === "unknownUser") {
                _self.user = {
                  id: false,
                  username: "",
                  role: "user",
                  color: "",
                  fingerPrint: "",
                };
                sessionStorage.removeItem("user");
              }
            }
          );
        }
      });
  },
};
</script>
