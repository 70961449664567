<template>
  <div
    v-if="products.length <= 0"
    class="c-cart-text mx-auto small text-center py-4 pe-4 pe-md-5 px-lg-4 px-xxl-5"
  >
    <p>
      {{ $t("cart.intro") }}
    </p>
  </div>

  <div
    class="c-cart-body d-flex flex-column justify-content-between position-relative w-100 flex-fill"
    v-else
  >
    <div class="c-cart-list flex-fill py-4 pe-4 pe-md-5 px-lg-4 px-xxl-5">
      <div
        v-for="(product, index) in products"
        :key="index"
        class="c-cart-list__item position-relative"
      >
        <div class="row flex-nowrap">
          <div class="col-auto c-cart-list__item__img">
            <div class="cart-item-img">
              <img
                :src="product.thumb"
                class="img-fluid cart-item-img-source"
                width="100"
                height="120"
                alt="alt-text"
              />
            </div>
          </div>
          <div class="col-auto flex-fill c-cart-list__item__details ps-1">
            <div class="c-cart-list__item__details__title">
              {{ product.title }}
            </div>
            <div class="c-cart-list__item__characteristics small mt-2">
              <div><span class="me-1">Kleur</span> {{ product.variant }}</div>
              <div><span class="me-1">Maat</span> {{ product.size }}</div>
            </div>
            <div class="c-cart-list__item__data d-flex mt-2">
              <div class="c-cart-list__item__data__quantity flex-fill">
                <!-- TODO: Implement vue number input -->
                <div
                  class="cart-number-input vue-number-input vue-number-input--inline vue-number-input--controls"
                >
                  <button
                    class="vue-number-input__button vue-number-input__button--minus"
                    :class="{ disabled: product.quantity <= 1 }"
                    type="button"
                    tabindex="-1"
                    v-on:click.prevent="
                      updateQuantity(
                        product.id,
                        product.size,
                        product.variantCode,
                        -1
                      )
                    "
                  ></button>
                  <input
                    class="vue-number-input__input"
                    type="number"
                    min="1"
                    max="10"
                    v-model.number="product.quantity"
                    step="1"
                    autocomplete="off"
                    @change="updateQuantityTo(product)"
                  />
                  <button
                    class="vue-number-input__button vue-number-input__button--plus"
                    :class="{ disabled: product.quantity > 9 }"
                    type="button"
                    tabindex="-1"
                    v-on:click.prevent="
                      updateQuantity(
                        product.id,
                        product.size,
                        product.variantCode,
                        1
                      )
                    "
                  ></button>
                </div>
              </div>
              <div class="c-cart-list__item__data__price">
                <div
                  v-if="product.hasPromo"
                  class="card-price d-flex flex-column flex-lg-row flex-xl-column text-end"
                >
                  <div class="c-original me-lg-2 me-xl-0">
                    € {{ roundPrice(product.price) }}
                  </div>
                  <div class="c-discount js-test">€ {{ roundPrice(product.promoPrice) }}</div>
                </div>
                <div
                  v-else
                  class="card-price d-flex flex-column flex-lg-row flex-xl-column text-end"
                >
                  <div class="c-unit">€ {{ roundPrice(product.price) }}</div>
                </div>
              </div>
            </div>
          </div>
          <button
              class="btn cart-item-remove"
              v-on:click.prevent="openPopup(product)"
          >
            <svg width="19" height="19"><use href="#icon-trash"></use></svg>
          </button>
        </div>
      </div>
    </div>
    <div
      class="c-cart-footer bg-light py-3 px-3 px-md-5 px-lg-4 px-xxl-5 d-flex flex-column"
    >
      <h4>Je besteloverzicht</h4>
      <div class="d-flex fw-bold mt-1">
        <span class="flex-fill">{{ cartCounter }} item(s)</span>
        <span>€ {{ cartTotal }}</span>
      </div>
      <button
        type="submit"
        class="btn btn-cart-submit rounded-1 d-flex align-items-center justify-content-center btn-primary text-uppercase mt-3"
        v-on:click.prevent="onCheckout"
      >
        {{ $t("cart.btnOrder") }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref, toRef } from "vue";

export default {
  setup(props) {
    const products = toRef(props, "products");
    const cartCounter = toRef(props, "cartCounter");
    const cartTotal = toRef(props, "totalPrice");

    return {
      products,
      cartCounter,
      cartTotal,
    };
  },
  props: ["products", "cartCounter", "totalPrice"],
  methods: {
    updateQuantity(id, size, variantCode, step) {
      this.$emit("updateProductQuantity", {
        id: id,
        size: size,
        variantCode: variantCode,
        step: step,
      });
    },
    updateQuantityTo(product) {
      if (product.quantity > 10) {
        product.quantity = 10;
      } else if (product.quantity < 1) {
        this.$emit("removeProductFromCart", {
          id: product.id,
          size: product.size,
          variantCode: product.variantCode,
        });
      }
    },
    removeProductFromCart(id, size, variantCode) {
      this.$emit("removeProductFromCart", {
        id: id,
        size: size,
        variantCode: variantCode,
      });
    },
    onCheckout() {
      window.location = liveShoppingStream.options.checkoutLink;
    },
    roundPrice(price) {
      return price.toFixed(2);
    },
    openPopup(product) {
      this.$emit("openRemoveProductFromCartPopup", product);
    }
  },
  components: {
  },
};
</script>