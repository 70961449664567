<template>
  <div
    v-if="products.length <= 0"
    class="c-cart-text mx-auto small text-center py-4 pe-4 pe-md-5 px-lg-4 px-xxl-5"
  >
    <p>
      {{ $t("wishlist.intro") }}
    </p>
  </div>

  <div
    class="c-wishlist-body d-flex flex-column justify-content-between position-relative w-100 flex-fill"
    v-else
  >
    <div class="c-wish-list flex-fill py-4 pe-4 pe-md-5 px-lg-4 px-xxl-5">
      <TransitionGroup
        tag="div"
        :css="false"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @leave="onLeave"
      >
        <ProductCard
          v-for="(product, index) in products"
          :productInfo="product"
          :variantInfo="
            product.variation.find((x) => x.variantCode == product.variantCode)
          "
          :inWishlist="product.inWishlist"
          :key="product.variantCode"
          :data-index="index"
        ></ProductCard>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { ref, toRef } from "vue";
import ProductCard from "./ProductCard.vue";

export default {
  setup(props) {
    const products = toRef(props, "products");
    const listCounter = toRef(props, "listCounter");

    return { products, listCounter };
  },
  props: ["products", "listCounter"],
  methods: {
    removeFromWishlist(id) {
      this.$emit("removeProductFromCart", {
        id: id,
        size: size,
        variantCode: variantCode,
      });
    },
    onBeforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(-30px)";
    },
    onEnter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        delay: el.dataset.index * 0.15,
        onComplete: done,
      });
      done();
    },
    onLeave(el, done) {
      gsap.to(el, {
        opacity: 0,
        y: 30,
        delay: el.dataset.index * 0.15,
        onComplete: done,
      });
      done();
    },
  },
  components: {
    ProductCard,
  },
};
</script>
