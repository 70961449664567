<template>
  <Transition name="productDetail">
    <aside
      v-if="active"
      class="c-section c-section--detail position-fixed d-flex flex-column"
    >
      <!-- Livestream active: article detail is visible -->
      <div class="c-section__body flex-fill d-block">
        <div class="article-detail px-4 pb-5 p-lg-4 px-xxl-5 pb-xxl-5">
          <div class="article-detail__btn-back">
            <div class="article-detail__btn-back__inner">
              <button
                v-on:click.prevent="goBack"
                class="btn d-flex align-items-center fw-normal p-0 rounded-0"
              >
                <svg class="icon-inactive" width="16" height="12">
                  <use href="#icon-arrow-back"></use>
                </svg>
                {{ $t("general.back") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 position-relative">
              <div v-for="(variant, index) in product.variation">
                <div
                  style="--swiper-navigation-color: #aeacac"
                  class="swiper-article mt-lg-1 position-relative"
                  v-show="index == selectedVariant"
                >
                  <swiper
                    :navigation="{
                      nextEl: `.swiper-button-next--${index}`,
                      prevEl: `.swiper-button-prev--${index}`,
                    }"
                    :modules="swiperModules"
                    effect="fade"
                    :slides-per-view="1"
                    :loop="false"
                    @swiper="onSwiperReady"
                  >
                    <swiper-slide v-for="img in variant.images">
                      <img :src="img" />
                    </swiper-slide>
                  </swiper>
                  <div
                    class="swiper-button-next"
                    :class="`swiper-button-next--${index}`"
                  >
                    <svg class="icon-inactive" width="24" height="18">
                      <use href="#icon-arrow-right"></use>
                    </svg>
                  </div>
                  <div
                    class="swiper-button-prev"
                    :class="`swiper-button-prev--${index}`"
                  >
                    <svg class="icon-inactive" width="24" height="18">
                      <use href="#icon-arrow-left"></use>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                class="d-flex align-items-center justify-content-between w-100 mt-3 text-start"
              >
                <p><strong>{{ product.variation[selectedVariant].title }}</strong></p>
                <span
                  v-on:click.stop="onAddToWishlist"
                  class="btn-wishlist"
                  :class="{
                    active: product.variation[selectedVariant].inWishlist,
                  }"
                  href="#"
                >
                  <svg class="icon-inactive" width="26" height="24">
                    <use href="#icon-wishlist-inactive"></use>
                  </svg>

                  <svg class="icon-active" width="26" height="24">
                    <use href="#icon-wishlist-active"></use>
                  </svg>
                </span>
              </div>
              <div class="d-block w-100 text-start">
                <div
                  v-if="
                    product.variation[selectedVariant].promotionPrice !==
                      null &&
                    product.variation[selectedVariant].promotionPrice > 0
                  "
                  class="card-price d-flex"
                >
                  <div class="c-original me-2">
                    €
                    {{
                      parseFloat(
                        product.variation[selectedVariant].price
                      ).toFixed(2)
                    }}
                  </div>
                  <div class="c-discount">
                    €
                    {{
                      parseFloat(
                        product.variation[selectedVariant].promotionPrice
                      ).toFixed(2)
                    }}
                  </div>
                </div>
                <div v-else class="card-price d-flex">
                  <div class="c-unit">
                    €
                    {{
                      parseFloat(
                        product.variation[selectedVariant].price
                      ).toFixed(2)
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-12 article-detail-options d-flex flex-wrap mt-3 mt-lg-4"
            >
              <div
                v-on:click.prevent="onClick(index)"
                v-for="(variant, index) in product.variation"
                class="article-detail-options__item me-2"
              >
                <input
                  type="radio"
                  name="first"
                  :value="index"
                  title="title"
                  :id="`option${index}`"
                  :checked="index == selectedVariant"
                />
                <label title="title" :for="`option${index}`" :class="{ 'fallback': !checkImageUrl(variant.thumb) }">
                  <img
                    :src="variant.thumb"
                    class="product-detail-configurator-option-image"
                    alt=""
                    width="40"
                    height="50"
                  />
                </label>
              </div>
            </div>
            <div class="col-12 mt-4 article-detail__form mt-3 mt-lg-4">
              <select
                class="form-select article-detail__form__select rounded-1"
                aria-label="Default select example"
                v-model="selectedSize"
              >
                <option value="" selected disabled>
                  {{ $t("productDetail.chooseSize") }}
                </option>

                <option
                  v-for="value in product.variation[selectedVariant].sizes"
                  :value="value.code"
                  :disabled="!value.inStock"
                >
                  {{ value.name }}
                  <span v-if="!value.inStock">
                    - {{ $t("productDetail.noStockOfSize") }}</span
                  >
                </option>
              </select>

              <div v-if="!productStock" class="card-stock mt-2">
                <small>{{ $t("productDetail.outOfStock") }}</small>
              </div>

              <button
                type="submit"
                class="btn btn-cart-add rounded-1 d-flex align-items-center justify-content-center btn-primary text-uppercase mt-3 w-100"
                :disabled="selectedSize == ''"
                :class="{ processing: processing }"
                v-on:click.prevent="addToCart"
              >
                <svg width="19" height="16"><use href="#icon-bag"></use></svg>
                <span class="label label--processing">{{
                  $t("productDetail.processing")
                }}</span>
                <span class="label label--default">{{
                  $t("productDetail.order")
                }}</span>
              </button>

              <div class="text-danger small" v-if="orderError">
                {{ orderError }}
              </div>
            </div>

            <div class="col-12 article-detail__descr">
              <div
                class="article-detail__descr__inner small pt-3 pt-lg-4 pb-3 border-bottom border-light"
              >
                <h3>
                  {{ $t("productDetail.titleDescription") }}
                </h3>
                <div class="article-detail__descr__text fw-light">
                  <p>
                    {{ product.variation[selectedVariant].description }}
                  </p>
                </div>
              </div>

              <div class="article-detail__descr__inner small pt-3 pt-lg-4 pb-3">
                <h3>{{ $t("productDetail.titleInfo") }}</h3>
                <div class="article-detail__descr__text">
                  <dl>
                    <dt>{{ $t("productDetail.infoFabric") }}</dt>
                    <dd>
                      {{ product.variation[selectedVariant].material }}
                    </dd>

                    <dt v-if="product.variation[selectedVariant].features">{{ $t("productDetail.infoCharacteristics") }}</dt>
                    <dd v-if="product.variation[selectedVariant].features">
                      <span
                          v-html="product.variation[selectedVariant].features"
                      ></span>
                    </dd>

                    <dt>{{ $t("productDetail.infoSKU") }}</dt>
                    <dd>
                      {{ product.variation[selectedVariant].variantCode }}
                    </dd>
                  </dl>

                  <div class="d-flex align-items-end">
                    <svg
                      class="washing-sprite mr-2"
                      role="img"
                      alt="Washing sprite"
                      v-for="icon in product.variation[selectedVariant]
                        .washing_instructions"
                    >
                      <use :xlink:href="'#x' + icon"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aside>
  </Transition>
</template>

<script>
import { watch, ref, toRef, computed } from "vue";
import { Navigation, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  setup(props) {
    const product = toRef(props, "product");
    const active = toRef(props, "active");
    const processing = toRef(props, "processing");
    const orderError = toRef(props, "orderError");
    const selectedVariant = toRef(props, "variant");

    const swipers = ref([]);
    const selectedSize = ref("");

    const productStock = computed(() => {
      let returnValue = false;

      props.product.variation[0].sizes.forEach((size) => {
        if (size.inStock) {
          returnValue = true;
        }
      });

      return returnValue;
    });

    const swiperModules = [Navigation, EffectFade];

    watch(
      () => props.product,
      (first, second) => {
        //Reset selected variant
        // selectedVariant.value = 0;
        selectedSize.value = "";

        //Resetting the swipers when changing product
        for (let index in swipers.value) {
          swipers.value[index].slideTo(0, 10);
        }
      }
    );

    return {
      active,
      product,
      processing,
      orderError,
      productStock,
      selectedVariant,
      swiperModules,
      swipers,
      selectedSize,
      baseUrl,
    };
  },
  props: ["active", "product", "variant", "processing", "orderError"],
  methods: {
    onClick(e) {
      this.$emit("onToggleVariant", e);
      // this.selectedVariant = e;
    },
    onSwiperReady(e) {
      this.swipers.push(e);
    },
    addToCart(e) {
      this.$emit("addToCart", {
        id: this.product.id,
        variant: this.product.variation[this.selectedVariant].label,
        variantCode: this.product.variation[this.selectedVariant].variantCode,
        size: this.selectedSize,
        title: this.product.title,
        thumb:
          this.product.variation[this.selectedVariant].thumb.length > 0
            ? this.product.variation[this.selectedVariant].thumb
            : this.product.thumbnail,
        price: this.product.price,
        hasPromo:
          this.product.variation[this.selectedVariant].promotionPrice !==
            null &&
          this.product.variation[this.selectedVariant].promotionPrice > 0,
        promoPrice: this.product.variation[this.selectedVariant].promotionPrice,
        quantity: 1,
      });
    },
    goBack(e) {
      this.$emit("closeProductDetail");
    },
    onAddToWishlist(e) {
      this.$emit(
        "onToggleWishlist",
        this.product.variation[this.selectedVariant].variantCode
      );
    },
    checkImageUrl(url) {
      const pattern = /\.(jpg|jpeg|png)$/i;
      return pattern.test(url);
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
